// Common styles
const disabledFill =
  ' disabled:bg-neutral-300 disabled:border-neutral-300 disabled:text-neutral-500 disabled:pointer-events-none'
const disabledOutline =
  ' disabled:bg-transparent disabled:border-neutral-300 disabled:text-neutral-500 disabled:pointer-events-none'

// Base styles
export const baseButtonClasses =
  'hss-button group/button inline-block whitespace-nowrap rounded-full border px-4 py-2 md:px-5 md:py-3 font-semibold transition-all duration-300 cursor-pointer'

// Core button styles
export const buttonClasses = {
  fillTealGreen:
    'bg-teal-green border-teal-green text-white hover:bg-teal-green hover:brightness-90 hover:border-teal-green active:bg-teal-green' +
    disabledFill,
  fillForestGreen:
    'bg-forest-green border-forest-green text-white hover:bg-forest-green hover:brightness-90 hover:border-forest-green' +
    disabledFill,
  fillVibrantGreen:
    'bg-vibrant-green border-vibrant-green text-neutral-900 hover:bg-vibrant-green hover:brightness-90 hover:border-vibrant-green' +
    disabledFill,
  fillWhite:
    'bg-white border-white text-neutral-900 hover:bg-white hover:border-white hover:brightness-90' +
    disabledFill,
  fillSafetyYellow:
    'bg-safety-yellow border-safety-yellow text-neutral-900 hover:bg-safety-yellow hover:border-safety-yellow hover:brightness-90' +
    disabledFill,
  fillCandleYellow:
    'bg-candle-yellow border-candle-yellow text-neutral-900 hover:bg-candle-yellow hover:border-candle-yellow hover:brightness-90' +
    disabledFill,
  fillGrey:
    'bg-neutral-300 border-neutral-300 text-neutral-900 hover:bg-neutral-300 hover:border-neutral-300 hover:brightness-90' +
    disabledFill,
  outlineTealGreen:
    'bg-transparent border-teal-green text-neutral-900 hover:bg-teal-green hover:border-teal-green hover:text-white' +
    disabledOutline,
  outlineForestGreen:
    'bg-transparent border-forest-green text-neutral-900 hover:bg-forest-green hover:border-forest-green hover:text-white' +
    disabledOutline,
  outlineVibrantGreen:
    'bg-transparent border-vibrant-green text-neutral-900 hover:bg-vibrant-green hover:border-vibrant-green hover:text-neutral-900' +
    disabledOutline,
  outlineWhite:
    'bg-transparent border-white text-neutral-900 hover:bg-white hover:border-white hover:text-neutral-900' +
    disabledOutline,
  outlineSafetyYellow:
    'bg-transparent border-safety-yellow text-neutral-900 hover:bg-safety-yellow hover:border-safety-yellow hover:text-neutral-900' +
    disabledOutline,
  outlineCandleYellow:
    'bg-transparent border-candle-yellow text-neutral-900 hover:bg-candle-yellow hover:border-candle-yellow hover:text-neutral-900' +
    disabledOutline,
  outlineGrey:
    'bg-transparent border-neutral-300 text-neutral-900 hover:bg-neutral-300 hover:border-neutral-300 hover:text-neutral-900' +
    disabledOutline,
}

// Determine styles to use
export const getButtonClasses = ({
  name,
  outline,
  disabledStyle,
}: {
  name: string
  outline?: boolean
  disabledStyle?: boolean
}) => {
  if (disabledStyle) {
    return 'bg-neutral-300 border-neutral-300 text-neutral-500 pointer-events-none'
  }

  if (name == 'tealGreen') {
    return outline
      ? buttonClasses.outlineTealGreen
      : buttonClasses.fillTealGreen
  } else if (name == 'forestGreen') {
    return outline
      ? buttonClasses.outlineForestGreen
      : buttonClasses.fillForestGreen
  } else if (name == 'vibrantGreen') {
    return outline
      ? buttonClasses.outlineVibrantGreen
      : buttonClasses.fillVibrantGreen
  } else if (name == 'white') {
    return outline ? buttonClasses.outlineWhite : buttonClasses.fillWhite
  } else if (name == 'safetyYellow') {
    return outline
      ? buttonClasses.outlineSafetyYellow
      : buttonClasses.fillSafetyYellow
  } else if (name == 'candleYellow') {
    return outline
      ? buttonClasses.outlineCandleYellow
      : buttonClasses.fillCandleYellow
  } else if (name == 'grey') {
    return outline ? buttonClasses.outlineGrey : buttonClasses.fillGrey
  } else {
    return ''
  }
}
