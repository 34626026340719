import type { SanityRefDeconstructed } from '~/types'

/**
 * Returns the correct link format from Sanity link field data
 *
 * @returns {string}
 */
interface GetLink {
  linkType?: string
  linkReference?: SanityRefDeconstructed
  linkSlug?: string
  linkExternal?: string
}

export const getLinkType = ({
  linkType,
  linkReference,
  linkSlug,
  linkExternal,
}: GetLink = {}) => {
  if (linkType == 'linkExternal' && linkExternal) {
    // External http link
    return linkExternal
  } else if (linkType == 'linkReference' && linkReference?.slug?.current) {
    // Dynamic reference link (prepend vertical for category pages)
    if (linkReference?.type == 'hire' || linkReference?.type == 'buy') {
      return `/${linkReference.type}/c/${linkReference.slug.current}`
    } else {
      return '/' + linkReference.slug.current
    }
  } else if (linkType == 'linkSlug' && linkSlug) {
    // Manually entered links
    return linkSlug
  } else {
    return ''
  }
}

/**
 * Trims a string by a passed length
 *
 * @returns {string}
 */
export const getExcerpt = (string: string, maxLength: number) => {
  return string.length > maxLength
    ? string.substring(0, maxLength) + '...'
    : string
}

/**
 * Converts camel case text to title case
 */
export const caseCamelToTitle = (string: string): string => {
  const result = string.replace(/([A-Z])/g, ' $1')

  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const caseSnakeKebabToTitle = (string: string) => {
  return string
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
}

/**
 * Round a number to a certain set of digits (toFixed returns a string)
 * @ref https://stackoverflow.com/questions/2283566/how-can-i-round-a-number-in-javascript-tofixed-returns-a-string
 */
export const toFixedNumber = (
  num: number,
  digits: number,
  base?: number
): number => {
  const pow = Math.pow(base ?? 10, digits)
  return Math.round(num * pow) / pow
}
