// Global packages and components
import { clsx } from 'clsx'
import { Link } from '@remix-run/react'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { baseButtonClasses, getButtonClasses, getLinkType } from '~/utils'

// Types
import type { SanityRefDeconstructed } from '~/types'

type ButtonStyles =
  | 'vibrantGreen'
  | 'tealGreen'
  | 'forestGreen'
  | 'safetyYellow'
  | 'candleYellow'
  | 'grey'
  | 'white'

export interface ButtonProps {
  title: string
  to?: string
  linkType?: string
  linkReference?: SanityRefDeconstructed
  linkSlug?: string
  linkExternal?: string
  fullWidth?: boolean
  hasArrow?: boolean
  disabled?: boolean
  outline?: boolean
  style?: ButtonStyles
  mimic?: boolean
  minWidth?: boolean
  onClick?: () => void
  type?: 'button' | 'submit'
}

interface ButtonInnerProps {
  title: string
  hasArrow?: boolean
  outline?: boolean
}

// Inner button
const ButtonInner = ({ title, hasArrow, outline }: ButtonInnerProps) => (
  <span
    className={clsx({
      'flex items-center justify-between md:min-w-28 lg:min-w-40': hasArrow,
    })}
  >
    <span className="inline-block">{title}</span>
    {hasArrow && (
      <span
        className={clsx(
          'ml-3 flex size-7 items-center justify-center rounded-full transition-transform duration-500 group-hover/button:translate-x-1',
          {
            'bg-white': !outline,
          }
        )}
      >
        <ArrowRightIcon className="size-4 text-neutral-900" />
      </span>
    )}
  </span>
)

// Main export
const Button = ({
  title,
  to,
  linkType,
  linkReference,
  linkSlug,
  linkExternal,
  fullWidth,
  hasArrow,
  disabled,
  style = 'vibrantGreen',
  outline,
  mimic,
  minWidth,
  onClick,
  type = 'button',
}: ButtonProps) => {
  if (!title) return null

  const regex = new RegExp('^(http|https|mailto|tel)://', 'i')

  const classes = clsx(baseButtonClasses, {
    'block w-full': fullWidth,
    'text-center': !hasArrow,
    'min-w-[12rem]': minWidth,
    [getButtonClasses({
      name: style,
      outline: outline ?? false,
      disabledStyle: !onClick && disabled ? true : false,
    })]: style,
  })

  const link =
    to ?? getLinkType({ linkType, linkReference, linkSlug, linkExternal })

  return mimic ? (
    <span className={classes}>
      <ButtonInner
        title={title}
        hasArrow={hasArrow}
        outline={outline}
      />
    </span>
  ) : onClick || type === 'submit' ? (
    <button
      onClick={onClick}
      className={classes}
      disabled={disabled}
      type={type}
    >
      <ButtonInner
        title={title}
        hasArrow={hasArrow}
        outline={outline}
      />
    </button>
  ) : link && regex.test(link) ? (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className={classes}
    >
      <ButtonInner
        title={title}
        hasArrow={hasArrow}
        outline={outline}
      />
    </a>
  ) : link ? (
    <Link
      to={link}
      className={classes}
    >
      <ButtonInner
        title={title}
        hasArrow={hasArrow}
        outline={outline}
      />
    </Link>
  ) : null
}

export default Button
